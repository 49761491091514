.portfolio-section {
  padding: 60px 0;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2em;
  font-weight: bold;
}

.portfolio-item {
  margin-bottom: 40px;
}

.portfolio-item img {
  width: 100%;
  height: auto;
  aspect-ratio: 4 / 3; /* これで縦横比を4:3に固定 */
  object-fit: cover;
  margin-bottom: 20px;
}

.portfolio-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.portfolio-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.portfolio-item p {
  font-size: 1em;
  color: #6c757d;
}
