.about-page {
  padding: 10px 0 60px 0;
}

.page-title {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 40px;
}

.global-illustration {
  max-width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.company-title {
  font-size: 2em;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.company-table th,
.company-table td {
  vertical-align: middle;
}

.company-table th {
  width: 20%;
  background-color: #f1f1f1;
}

.company-table td {
  width: 80%;
}

@media (max-width: 768px) {
  .page-title {
    font-size: 2em;
  }

  .company-title {
    font-size: 1.5em;
  }

  .company-table th,
  .company-table td {
    font-size: 0.9em;
  }
}
