.hero-section {
  color: white;
  text-align: left;
  overflow: hidden;
}

.carousel-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  width: 100%;
  padding: 100px 0;
}

.hero-title {
  font-size: 3em;
  font-weight: bold;
  animation: fadeInSlideUp 1.5s ease-in-out;
}

.hero-subtitle {
  font-size: 1.5em;
  margin-bottom: 20px;
  animation: fadeInSlideUp 2s ease-in-out;
}

.hero-image {
  animation: scaleUpDown 4s ease-in-out infinite;
}

@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleUpDown {
  0%,
  100% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
}

.hero-section .btn {
  animation: fadeInSlideUp 2.5s ease-in-out;
}

.carousel-item h1 {
  font-size: 3em;
  font-weight: bold;
  animation: fadeInSlideUp 1.5s ease-in-out;
}

.hero-button {
  margin-top: 20px;
  animation: fadeInSlideUp 2.5s ease-in-out;
}
