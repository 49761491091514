.home-page {
  font-family: Arial, sans-serif;
}

.services-section,
.portfolio-section,
.testimonials-section,
.cta-section {
  padding: 60px 0;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2em;
  font-weight: bold;
}

.service-card,
.portfolio-card,
.testimonial-card {
  margin-bottom: 30px;
}

.cta-section {
  background-color: #333;
  color: white;
}

.cta-title {
  font-size: 2em;
  font-weight: bold;
}

.cta-subtitle {
  font-size: 1.2em;
}
