.contact-page {
  padding: 10px 0 60px 0;
}

.page-title {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 40px;
}

.contact-image {
  max-width: 200px;
  height: auto;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .page-title {
    font-size: 2em;
  }
}
