/* Reset some default browser styles */
body,
html,
ul {
  margin: 0;
  padding: 0;
}

.header {
  background: linear-gradient(
    90deg,
    rgba(29, 53, 87, 1) 0%,
    rgba(69, 123, 157, 1) 100%
  );
  padding: 20px 0;
  color: white;
}

header img {
  width: 150px; /* 適切な幅に設定 */
  height: auto; /* 高さを自動調整 */
  max-height: 60px; /* 最大高さを設定（必要に応じて調整） */
  object-fit: contain; /* 画像の比率を維持 */
}

.navbar {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.navbar-logo {
  font-size: 1.5em;
  font-weight: bold;
}

.navbar-links .nav-link {
  color: white !important;
  font-size: 1em;
  transition: color 0.3s;
}

.navbar-links .nav-link:hover {
  color: rgba(29, 53, 87, 1) !important;
}

.language-switcher {
  display: flex;
  align-items: center;
  gap: 10px;
}

.language-switcher .dropdown-toggle {
  background: transparent;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.language-switcher .dropdown-toggle:hover,
.language-switcher .dropdown-toggle.active {
  background: white;
  color: rgba(29, 53, 87, 1);
}

@media (max-width: 768px) {
  .navbar-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .language-switcher {
    margin-top: 10px;
  }
}
