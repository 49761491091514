.news-section {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2em;
  font-weight: bold;
}

.news-item {
  margin-bottom: 30px;
}

.news-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.news-content:hover {
  transform: translateY(-10px);
}

.news-content h3 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.news-content p {
  font-size: 1em;
  color: #6c757d;
  flex-grow: 1; /* Flexboxでの余白確保 */
}

.news-content small {
  color: #6c757d;
}

.news-image {
  width: 100%;
  height: 200px; /* 固定の高さを設定 */
  object-fit: cover; /* 画像をコンテナ内に収める */
  border-radius: 5px;
  margin-bottom: 15px;
}
