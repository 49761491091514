.news-details {
  padding: 20px 0;
}

.news-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px; /* 下の余白を追加 */
  display: block; /* 画像をブロック要素にする */
}

.news-details h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.news-details p {
  font-size: 1em;
  color: #6c757d;
}

.news-details .news-detail-content {
  text-align: left; /* コンテンツを左寄せ */
}

.news-details .news-detail-content p,
.news-details .news-detail-content ul {
  margin-bottom: 20px; /* パラグラフとリストの下に余白を追加 */
}
