.services-section {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2em;
  font-weight: bold;
}

.service-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.service-card img {
  height: 200px;
  object-fit: cover;
}

.service-card .card-title {
  font-size: 1.25em;
  font-weight: bold;
  margin-top: 15px;
}

.service-card .card-text {
  font-size: 1em;
  color: #6c757d;
}

.service-card .card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
