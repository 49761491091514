footer {
  background: linear-gradient(
    90deg,
    rgba(29, 53, 87, 1) 0%,
    rgba(69, 123, 157, 1) 100%
  );
  color: white;
  padding: 20px 0;
  text-align: center;
  position: relative;
  bottom: 0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer p {
  margin: 10px 0;
}

@media (max-width: 768px) {
  .footer-container {
    padding: 0 10px;
  }
}
